<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de Notificações</p>
        <v-btn
          v-if="checkPermission('notification.create')"
          small
          depressed
          color="fmq_gray"
          dark
          to="/notificacao-criar"
          >Nova Notificação</v-btn
        >
      </div>
      <NotificacoesTabela
        :headers="headers"
        :notificacoes="notificacao"
        :total="totalNotificacao"
        :page="formData.page"
        :pageText="pageText"
        :loading="loading"
        @handleFilter="filter"
        @excluir="excluirModal"
        @enviar="enviarModal"
      >
        <template v-slot:type>
          <v-text-field
            v-model="formData.type"
            placeholder="Busque pelo tipo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:subject>
          <v-text-field
            v-model="formData.subject"
            placeholder="Busque pelo resumo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:message>
          <v-text-field
            v-model="formData.message"
            placeholder="Busque pela mensagem"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:status>
          <v-autocomplete
            v-model="formData.status"
            :items="['Rascunho', 'Processando', 'Enviado', 'Erro de envio']"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pela status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </NotificacoesTabela>
      <AlertConfirmation
        :dialog="dialog"
        :dialogMessage="dialogMessage"
        @close="dialog = false"
        @accept="excluir"
      />
      <AlertConfirmation
        :dialog="dialogSend"
        dialogMessage="Tem certeza que deseja enviar essa notificação?"
        @close="dialogSend = false"
        @accept="enviar"
      />
      <AlertSuccess
        :dialog="success"
        :dialogMessage="dialogMessageSucess"
        @close="
          success = !success;
          buscar(formData);
        "
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import { checkPermission } from "@/utils";
import NotificacoesTabela from "@/components/notificacoes/NotificacoesTabela.vue";
import { searchNotification, sendNotification } from "@/services/notification";
import { deleteNotification } from "@/services/notification";
export default {
  name: "Notificacoes",
  components: { NotificacoesTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Notificações",
        disabled: true,
        to: "Notificações",
      },
    ],
    headers: [
      { text: "Tipo", value: "type" },
      { text: "Assunto", value: "subject" },
      { text: "Mensagem", value: "message" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "id", sortable: false },
    ],
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      type: null,
      subject: null,
      message: null,
      status: null,
    },
    notificacao: [],
    totalNotificacao: null,
    pageText: null,
    loading: false,
    dialog: false,
    error: false,
    success: false,
    messageError: null,
    dialogMessage: "",
    dialogMessageSucess: "",
    dialogSend: false,
  }),
  created() {
    if (this.$store.state.searchNotificationHistory.length) {
      this.formData = { ...this.$store.state.searchNotificationHistory[0] };
    }
    this.$store.dispatch("setDuplicateNotification", null);
    this.buscar(this.formData);
  },
  methods: {
    checkPermission,
    async buscar(payload) {
      this.notificacao = [];
      this.$store.dispatch("setSearchNotificationHistory", payload);
      try {
        this.loading = true;
        const resp = await searchNotification(payload);
        this.notificacao = resp.data;
        this.totalNotificacao = resp.meta.total;
        this.formData.page = resp.meta.currentPage;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await deleteNotification(this.itemId).then(() => {
          this.dialogMessageSucess = "Notificação excluida com sucesso!";
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage =
        item.status === "Enviado"
          ? `Essa notificação já foi enviada, deseja excluir definitivamente?`
          : `Tem certeza que deseja excluir ${item.name}?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    enviarModal(item) {
      this.dialogSend = true;
      this.itemId = item.id;
    },
    filter(event) {
      this.$store.dispatch("setSearchNotificationHistory", this.formData);
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.buscar(this.formData);
    },
    clear() {
      this.$store.dispatch("setSearchNotificationHistory", {});
      (this.formData.type = null),
        (this.formData.subject = null),
        (this.formData.message = null),
        (this.formData.status = null),
        (this.formData.page = 1);
      this.buscar(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.buscar(this.formData);
    },
    async enviar() {
      this.error = false;
      this.success = false;
      this.dialogSend = false;
      try {
        await sendNotification(this.itemId).then(() => {
          this.dialogMessageSucess = "Notificação enviada com sucesso!";
          this.success = true;
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
  },
};
</script>
