<template>
  <v-data-table
    :headers="headers"
    :items="notificacoes"
    :items-per-page="20"
    :page="page"
    class="elevation-1 tabela-notificacao"
    locale="pt-BR"
    loading-text="Carregando"
    no-data-text="Nenhum dado encontrado"
    :loading="loading"
    :server-items-length="total"
    @update:options="handleFilter"
    :footer-props="{
      'items-per-page-text': 'Notificaçoes por página:',
      'items-per-page-options': [20, 50, 100],
      'show-first-last-page': true,
      'show-current-page': true,
      'page-text': pageText,
    }"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <template v-for="(header, index) in headers">
          <th :key="index">
            <slot :name="header.value"> </slot>
          </th>
        </template>
      </tr>
    </template>

    <template v-slot:[`item.message`]="{ item }">
      <v-tooltip bottom max-width="480">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            v-html="truncarString(item.message)"
          ></div>
        </template>
        <div v-html="item.message"></div>
      </v-tooltip>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <v-menu
        offset-y
        v-if="
          checkPermission([
            'notification.show',
            'notification.edit',
            'notification.duplicate',
            'notification.delete',
          ])
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed color="fmq_gray" dark v-bind="attrs" v-on="on">
            Opções <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="checkPermission('notification.show')"
            @click="gotTo('NotificacaoVisualizar', item.id)"
          >
            <v-list-item-title>Visualizar</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="checkPermission('notification.edit')"
            @click="gotTo('NotificacaoEditar', item.id)"
          >
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="checkPermission('notification.duplicate')"
            @click="duplicar(item)"
          >
            <v-list-item-title>Duplicar</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="item.status === 'Rascunho'"
            @click="$emit('enviar', item)"
          >
            <v-list-item-title>Enviar</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="checkPermission('notification.delete')"
            @click="$emit('excluir', item)"
          >
            <v-list-item-title>Excluir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { checkPermission } from "@/utils";
export default {
  name: "NotificacoesTabela",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    notificacoes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageText: {
      type: String,
      default: "-",
    },
  },
  data: () => ({
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: null,
      page: null,
    },
  }),
  created() {
    this.formData.page = this.page;
  },
  methods: {
    checkPermission,
    handleFilter(event) {
      (this.formData.orderBy = event.sortBy[0]),
        (this.formData.orderSorted = event.sortDesc[0] ? ":desc" : ":asc"),
        (this.formData.perPage = event.itemsPerPage),
        (this.formData.page = event.page);
      this.$emit("handleFilter", this.formData);
    },
    gotTo(name, id) {
      this.$router.push({ name: name, params: { id: id } });
    },
    truncarString(str) {
      if (str) {
        if (str.length <= 255) {
          return str;
        } else {
          return str.substring(0, 255) + "...";
        }
      }
    },
    duplicar(item) {
      this.$store.dispatch("setDuplicateNotification", item).then(() => {
        this.$router.push({ name: "NotificacaoCriar" });
      });
    },
  },
};
</script>
<style lang="scss">
.tabela-notificacao tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.tabela-notificacao tbody tr:nth-of-type(odd) {
  background-color: #eeeeee;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  padding: 0px 4px;
}
</style>
